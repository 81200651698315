import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { images } from '../assets';

interface TooltipProps {
  text: string;
  content: string;
  projectId?: string;
  thumbnail?: string;
  websiteUrl?: {
    url: string;
    name: string;
  };
}

const About: React.FC = () => {
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleProjectClick = (projectId: string) => {
    window.location.href = `/${projectId}`;
  };

  const Tooltip = ({ text, content, projectId, thumbnail, websiteUrl }: TooltipProps) => (
    <Tippy 
      content={
        <div className="tooltip-content">
          {thumbnail && (
            <div 
              className="tooltip-thumbnail-container"
              onClick={() => setExpandedImage(thumbnail)}
            >
              <img 
                src={thumbnail} 
                alt={content} 
                className="tooltip-thumbnail"
              />
              <div className="thumbnail-overlay">
                <i className="bi bi-zoom-in"></i>
              </div>
            </div>
          )}
          <div className="tooltip-description">{content}</div>
          {(websiteUrl || projectId) && (
            <div className="tooltip-actions">
              {websiteUrl && (
                <a 
                  href={websiteUrl.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="website-link"
                >
                  {websiteUrl.name} <i className="bi bi-link-45deg"></i>
                </a>
              )}
              {projectId && (
                <button 
                  className="view-project-link"
                  onClick={() => handleProjectClick(projectId)}
                >
                  View Project Details <i className="bi bi-box-arrow-up-right"></i>
                </button>
              )}
            </div>
          )}
        </div>
      }
      placement="right"
      interactive={true}
      appendTo="parent"
      maxWidth={400}
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
              padding: 16
            }
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom', 'top', 'left'],
              padding: 16
            }
          }
        ]
      }}
    >
      <span className="reference-term">{text}</span>
    </Tippy>
  );

  return (
    <div className="about-page">
      <div className="about-container">
        <h2>A little about me</h2>
        <div className="content">
          <div className="image-quote-container">
            <div className="profile-image">
              <img src={images.profileImage} alt="" />
            </div>
            <blockquote className="blockquote">
              <p>"We are more fulfilled when we are involved with something bigger than ourselves"</p>
              <footer className="blockquote-footer">
                - Astronaut John Glenn
              </footer>
            </blockquote>
          </div>

          <p>
            I've been fortunate to work on awe-inspiring, impactful, and large-scale efforts at <Tooltip text="NASA" content="Managed launch control application development" thumbnail="/img/nasa.jpg" websiteUrl={{
                url: "https://blogs.nasa.gov/groundsystems/2017/04/05/new-ground-launch-sequencer-software-demonstrated-in-launch-control-center/",
                name: "Launch Countdown Software News"
              }} />, the <Tooltip text="U.S. Senate" content="Served as Space & Science Subcommittee professional staff to Sen. Bill Nelson" thumbnail="/img/senate.jpg"  />, and <Tooltip text="Amazon" content="Managed massive scale data analytics programs" thumbnail="/img/amazon.jpg"/>, but I've always been an entrepreneur and creator at heart. I co-founded a small tech <Tooltip text="startup" content="eJourney 360 - one of the very first 360 virtual tour companies back in early 2000s" thumbnail="/img/ejourney.jpg" /> while in college and am always working on <a href="/projects" onClick={(e) => { e.preventDefault(); window.location.href = '/projects'; }}>passion projects</a>. I'd like to think that my curiosity and creativity led me to some tough yet rewarding careers. As I look to the future, I'm excited to bring fresh ideas and new perspectives to longstanding problems in entirely new areas.
          </p>
          <p>
          I've always been fascinated by how people come up with ideas and bring them to life. At NASA, I co-led and helped grow a grassroots group called the <Tooltip 
              text="Spaceport Innovators" 
              content="A grassroots group of hundreds of employees who wanted to make NASA a more innovative place"
              websiteUrl={{
                url: "https://appel.nasa.gov/2016/03/25/spaceport-innovators-keep-on-innovating/",
                name: "Spaceport Innovators Keep On Innovating"
              }}
            /> to strengthen a culture of innovation, co-authored an <Tooltip 
            text="Innovation Guide" 
            content="NASA Publication: The Employee's Guide on How to be Innovative at Kennedy Space Center"
          /> to help government employees navigate the creative process in a bureaucratic environment, built a simple <Tooltip 
          text="innovation assessment tool" 
          content="Anyone can innovate!" 
          projectId="hti" 
          thumbnail="/img/projects/hti/thumbnail.png"
          websiteUrl={{
            url: "https://www.howtoinnovate.com",
            name: "How To Innovate"
          }}
        />, and facilitated panels on transformative space policy and technology. I've also given talks, recorded <Tooltip 
        text="podcasts" 
        content="An unpublished podcast about the stories of innovation and the lessons of what it truly takes to grow something new." 
        projectId="seeded" 
        thumbnail="/img/projects/seeded/thumbnail.png"
        websiteUrl={{
          url: "https://seededpodcast.com/",
          name: "How To Innovate"
        }}
      />, and written about invention and inspiration including about <Tooltip 
        text="Chindogu" 
        content="Wrote a Chapter about Chindogu and the art of impractical inventions" 
        websiteUrl={{
          url: "https://us.macmillan.com/books/9781250288349/howtowinfriendsandinfluencefungi/",
          name: "How To Win Friends and Influence Fungi"
        }}
      />, the art of impractical inventions and how sometimes, the weirdest ideas and thought experiments can open the door to something great!
          </p>

          <p> 
            I enjoy creating and organizing unique and (hopefully) memorable events that bring people together. I founded and hosted a surprisingly popular nerdy, and sometimes salacious,  <Tooltip 
              text="monthly speaking series" 
              content="Nerd Nite Orlando" 
              projectId="nno" 
              thumbnail="/img/projects/nno/thumbnail.png"
              websiteUrl={{
                url: "https://orlando.nerdnite.com",
                name: "Nerd Nite Orlando"
              }}
            />. I've organized speed dating events that quickly became <Tooltip 
            text="speedfriending" 
            content="Speedfriending events" 
            projectId="speedfriending" 
            thumbnail="/img/projects/speedfriending/thumbnail.png"
            websiteUrl={{
              url: "https://www.speedfriending.org",
              name: "Speedfriending"
            }}
          /> events that I hope to host again soon. I was a community board member panel moderator for <Tooltip text="Maker Faire" content="Inaugural Orlando Maker Faire" />, helped organize talks for <Tooltip text="TEDx" content="TEDxOrlando" />, and had a small part in helping out with the <Tooltip text="Smithsonian's Future is Here Festival" content="Helped organize some of the talks, rode a hoverboard" />.
            
        
          Most recently, I started a unique  <Tooltip text="loop-based marathon" 
          content="Sloan's Lake Search Party" 
          projectId="slsp" 
          thumbnail="/img/projects/slsp/thumbnail.png" 
          /> around Denver's largest lake in search of a mythical creature called  <Tooltip text="Goosifer" 
          content="Blucifer+Canada Goose aka Sloan's Lake Monster & Colfax Creature" 
          websiteUrl={{ url: "https://goosifer.net", name: "Learn more at Goosifer.net" }} 
          />
          </p>

          <p>I'm sort of a Type II fun kind of person. I've completed a few marathons and <Tooltip text="ultramarathons" content="Biffledinked" />, including a 24-hour, 50-mile <Tooltip text="loop-based race" content="Joe Relaxo" />, a couple of <Tooltip text="Ironman triathlons" content="IMFL, IMCHOO" />, and a bunch of half Ironmans. I've somehow convinced a couple of my closest friends every year to hike above 14,000 feet at sunrise before biking and rafting all in <Tooltip text="one day" content="Buena Vista 3 (BV3) - Mt. Antero 2024" thumbnail="/img/projects/ahah/gallery1.jpg" projectId="ahah" />. I've also biked up the highest paved road in the U.S.,  <Tooltip text="Mt. Blue Sky" content="Bob Cook Memorial Hill Climb" />, ridden ~110 miles from <Tooltip text="Denver to Vail" content="Triple Bypass" />, and covered <Tooltip text="150 miles" content="Bike MS150 Charity Ride (solo during covid)" /> in a single day all on my trusty, albeit a bit heavy, gravel bike.
          </p>
          <p>
            In my free time, you'll find me floating down the river (sometimes in <Tooltip text="homemade 'boats'" content="Competing in the longest running whitewater festival, FIBARK, Hooligan race 2024" thumbnail="/img/projects/ahah/gallery5.jpg" projectId="ahah" />) , walking up <Tooltip text="mountains" content="So far I've hiked 40 of Colorado's mountains over 14,000 feet (aka 14ers)" />, biking around town, hanging with my ol' pup Banksy, and camping while also planning the next great adventure with my wife, Kayla. 
            </p>
        </div>
      </div>

      {/* Image Modal */}
      {expandedImage && (
        <div 
          className="image-modal-overlay"
          onClick={() => setExpandedImage(null)}
        >
          <div className="image-modal">
            <img src={expandedImage} alt="Expanded view" />
            <button 
              className="close-modal"
              onClick={() => setExpandedImage(null)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default About; 