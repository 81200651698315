import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface ListworthyProps {
  onClose: () => void;
}

export const listworthyData: ProjectData = {
  id: 'listworthy',
  title: 'Listworthy',
  tagline: 'Plan and do worthy things.',
  year: '2025',
  role: 'Creator',
  status: 'ideas',
  type: 'software',
  thumbnailImage: '/img/projects/listworthy/thumbnail.png',
  overview: {
    description: "Listworthy helps you organize your goals, track your progress, and connect with others who share your aspirations. Simply start adding to your bucketlist and find resources and support for any goals you're willing to share with others.",
    motivation: "I believe that people who share the same goals and aspirations will be more likely to achieve them together.",
    solution: "A simple platform that allows you to create and share your bucketlist so that you can find resources and support to help you achieve your goals."
  },
  keyFeatures: [
    {
      title: 'Plan',
      description: 'Add up to 100 goals to your bucketlist.',
      icon: 'bi-list-check'
    },
    {
      title: 'Research',
      description: 'Find resources and support for your goals.',
      icon: 'bi-search'
    },
    {
      title: 'Connect',
      description: 'Find others who share your goals and aspirations and encourage each other to achieve them!',
      icon: 'bi-people'
    }
  ],
  gallery: [
    {
      image: '/img/projects/listworthy/gallery1.jpg',
      caption: 'Listworthy'
    },
    {
      image: '/img/projects/listworthy/gallery2.jpg',
      caption: 'Listworthy'
    },
    {
      image: '/img/projects/listworthy/gallery3.jpg',
      caption: 'Listworthy'
    },
    {
      image: '/img/projects/listworthy/gallery4.jpg',
      caption: 'Listworthy'
    }
  ],
  stats: [
  ],
  technologies: [
  ],
  links: [
    {
      url: 'https://listworthy.app',
      label: 'Visit Website',
      icon: 'bi-globe'
    }
  ]
};

const Listworthy: React.FC<ListworthyProps> = ({ onClose }) => {
  return <ProjectTemplate data={listworthyData} />;
};

export default Listworthy; 