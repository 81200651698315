import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SlidingPanel from './components/SlidingPanel';
import Hero from './components/Hero';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import ProjectPage from './components/ProjectPage';
import './styles/main.css';
import './styles/project-template.css';
import './styles/sliding-panel.css';
import './styles/app.css';
import './styles/portfolio.css';

const App: React.FC = () => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(window.innerWidth >= 1200);
  const location = useLocation();
  const isHeroPage = location.pathname === '/home';

  useEffect(() => {
    const handleResize = () => {
      const shouldBeExpanded = window.innerWidth >= 1200;
      setIsPanelExpanded(shouldBeExpanded);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const togglePanel = () => {
    setIsPanelExpanded(!isPanelExpanded);
  };

  return (
    <div className="App">
      <SlidingPanel 
        isExpanded={isPanelExpanded}
        onToggle={togglePanel}
      />
      <div className={`main-content ${isPanelExpanded ? 'panel-expanded' : ''} ${isHeroPage ? 'home-page' : ''}`}>
        <div className="content-wrapper">
          {isHeroPage ? (
            <Hero />
          ) : (
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Portfolio />} />
              <Route path="/:projectId" element={<ProjectPage />} />
            </Routes>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;