import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ContactAdventure from './ContactAdventure';

interface SlidingPanelProps {
  isExpanded: boolean;
  onToggle: () => void;
}

interface FeaturedProject {
  id: string;
  title: string;
  description: string;
}

const activityInfo = {
  biking: "Exploring Denver's hundreds of miles of trails and commuting by bike whenever possible.",
  hiking: "Summitting Colorado's 14ers in sometimes unique and crazy ways.",
  rafting: "Packrafting and boating with friends into the depths of Colorado.",
  snowboarding: "Chasing powder in the Colorado Rockies and always looking for new lines to ride.",
  travel: "Exploring new places, meeting new people, and collecting stories from around the world.",
  music: "Seeking out live shows and discovering new artists across different genres.",
  coffee: "Drinking PSLs and not being ashamed to admit it. Sometimes making my own syrups at home.",
  cocktails: "Experimenting with new twists on classic cocktails (current fav: Naked & Famous)"
};

const activityIcons = {
  biking: "bicycle",
  hiking: "compass",
  rafting: "water",
  snowboarding: "snow",
  travel: "airplane",
  music: "music-note",
  coffee: "cup-hot",
  cocktails: "cup"
};

type ActivityKey = keyof typeof activityInfo;

const projects: FeaturedProject[] = [
  {
    id: 'hti',
    title: 'How to Innovate',
    description: 'Making innovation accessible to everyone'
  },
  {
    id: 'muzed',
    title: 'Muzed',
    description: 'Bringing art to life through storytelling'
  },
  {
    id: 'slsp',
    title: "Sloan's Lake Search Party",
    description: 'A unique loop-based marathon event'
  }
];

const SlidingPanel: React.FC<SlidingPanelProps> = ({ isExpanded, onToggle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';
  const [expandedSection, setExpandedSection] = useState<'work' | 'favorites' | null>(null);
  const [expandedActivity, setExpandedActivity] = useState<ActivityKey | null>(null);
  const [showAdventure, setShowAdventure] = useState(false);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const isSmallScreen = window.innerWidth < 768;
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 5000); // Rotate every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isExpanded && 
          panelRef.current && 
          !panelRef.current.contains(event.target as Node)) {
        onToggle(); // Close panel
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded, onToggle]);

  const toggleSection = (section: 'work' | 'favorites') => {
    if (isSmallScreen) {
      setExpandedSection(expandedSection === section ? null : section);
    }
  };

  const toggleActivity = (activity: ActivityKey) => {
    setExpandedActivity(expandedActivity === activity ? null : activity);
  };

  const handleNextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const handlePrevProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  const handleNavClick = (path: string) => {
    // Force a full page refresh by changing window.location
    window.location.href = path;
  };

  return (
    <div 
      ref={panelRef}
      className={`sliding-panel ${isExpanded ? 'expanded' : ''}`}
    >
      <button 
        className="panel-toggle"
        onClick={onToggle}
        aria-label={isExpanded ? 'Collapse panel' : 'Expand panel'}
      >
        <i className={`bi bi-chevron-${isExpanded ? 'left' : 'right'}`}></i>
      </button>

      <div className="panel-content">
        <div className="profile">
          <div 
            className="profile-image-container"
            style={{
              '--favicon-url': `url(/img/jm.png)`
            } as React.CSSProperties}
            onClick={isExpanded ? () => handleNavClick('/') : onToggle}
            role="button"
            tabIndex={0}
          >
            {isExpanded && (
              <>
                <img src="/img/me.jpg" alt="" className="img-fluid rounded-circle" />
                <div className="profile-tooltip">Hanging out in Honduras!</div>
              </>
            )}
          </div>
          <h1><a href="#hero">Josh Manning</a></h1>
          <div className="social-links">
            <button 
              onClick={() => setShowAdventure(true)}
              className="adventure-link"
            >
              <i className="bi bi-compass"></i>
            </button>
            <a href="https://www.linkedin.com/in/joshmanning/" target="_blank" rel="noopener noreferrer" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a>
            <a href="https://www.facebook.com/joshjmanning" target="_blank" rel="noopener noreferrer" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/manning_space/" target="_blank" rel="noopener noreferrer" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
          </div>
        </div>

        <nav className="nav-menu">
          <ul>
            <li>
              <button 
                onClick={() => handleNavClick('/home')} 
                className={`nav-link ${location.pathname === '/home' ? 'active' : ''}`}
              >
                <i className="bi bi-house"></i>
                <span className="nav-text">Home</span>
              </button>
            </li>
            <li>
              <button 
                onClick={() => handleNavClick('/about')} 
                className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`}
              >
                <i className="bi bi-person"></i>
                <span className="nav-text">About</span>
              </button>
            </li>
            <li>
              <button 
                onClick={() => handleNavClick('/projects')} 
                className={`nav-link ${location.pathname === '/projects' ? 'active' : ''}`}
              >
                <i className="bi bi-lightbulb"></i>
                <span className="nav-text">Projects</span>
              </button>
            </li>
          </ul>
        </nav>

        {isExpanded && (
          <>
            <h3 className="section-header">Featured Projects</h3>
            <div className="work-links">
              <button 
                onClick={() => {
                  window.location.href = `/${projects[currentProjectIndex].id}`;
                  onToggle(); // Close panel after navigation
                }} 
                className="work-link"
              >
                {projects[currentProjectIndex].title}
                <p className="work-description">{projects[currentProjectIndex].description}</p>
              </button>
              <div className="carousel-controls">
                <button onClick={handlePrevProject} aria-label="Previous Project" className="carousel-control left">
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button onClick={handleNextProject} aria-label="Next Project" className="carousel-control right">
                  <i className="bi bi-chevron-right"></i>
                </button>
              </div>
            </div>
          </>
        )}

        <div className="collapsible-section">
          <h3 className="section-header" onClick={onToggle}>
            <i className="bi bi-heart-fill section-icon"></i>
            {isExpanded && <span className="section-text">I <i className="bi bi-heart-fill" style={{ fontSize: '0.9em' }}></i> these things</span>}
          </h3>
          {isExpanded && (
            <div className={`section-content ${(!isSmallScreen || expandedSection === 'favorites') ? 'expanded' : ''}`}>
              <div className="favorite-activities">
                {Object.keys(activityInfo).map(activity => (
                  <div 
                    key={activity}
                    className={`activity-tag ${expandedActivity === activity ? 'expanded' : ''}`}
                    onClick={() => toggleActivity(activity as ActivityKey)}
                  >
                    <div className="activity-content">
                      <i className={`bi bi-${activityIcons[activity as ActivityKey]}`}></i>
                      <span className="activity-label">{activity.charAt(0).toUpperCase() + activity.slice(1)}</span>
                      {expandedActivity === activity && (
                        <span className="activity-description">{activityInfo[activity as ActivityKey]}</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {showAdventure && <ContactAdventure onClose={() => setShowAdventure(false)} />}
    </div>
  );
};

export default SlidingPanel;