import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface SLSPProps {
  onClose: () => void;
}

export const slspData: ProjectData = {
  id: 'slsp',
  title: "Sloan's Lake Search Party (SLSP)",
  tagline: 'A unique loop-based marathon event',
  year: '2023',
  role: 'Search Guide',
  status: 'ongoing',
  type: 'event',
  thumbnailImage: '/img/projects/slsp/thumbnail.png',
  overview: {
    description: "A unique marathon event where participants complete ten 2.62-mile loops around Sloan's Lake in Denver, Colorado - one loop every hour on the hour for 10 hours. While completing the full 26.2 miles, runners SEARCH for the mythical Goosifer (the Sloan's Lake Monster) and PARTY at basecamp with friends and family between loops.",
    motivation: "To create a unique event after realizing Sloan's Lake is nearly perfectly 1/10th of a marathon. Inspired by Denver's very own Blucifer and legends of Big Foot.",
    solution: "A loop-based marathon format where runners start and finish at the same spot every hour, allowing them to spend more time with friends and family at basecamp between loops. This creates a more social and festive atmosphere compared to traditional point-to-point marathons."
  },
  stats: [
    {
      value: '26.2',
      label: 'Total Miles',
      icon: 'bi-flag'
    },
    {
      value: '10',
      label: 'Loops',
      icon: 'bi-arrow-repeat'
    },
    {
      value: '10',
      label: 'Hours',
      icon: 'bi-clock'
    },
    {
      value: '50',
      label: 'Participants',
      icon: 'bi-people'
    }
  ],
  gallery: [
    {
      image: '/img/projects/slsp/gallery1.jpg',
      caption: 'SLSP Event'
    },
    {
      image: '/img/projects/slsp/gallery2.jpg',
      caption: 'SLSP Event'
    },
    {
      image: '/img/projects/slsp/gallery3.jpg',
      caption: 'SLSP Event'
    },
    {
      image: '/img/projects/slsp/gallery4.jpg',
      caption: 'SLSP Event'
    }
  ],
  resources: [
    {
      title: 'Race Registration',
      description: 'Sign up for the next SLSP event',
      url: 'https://runsignup.com/Race/CO/Denver/SLSP',
      icon: 'bi-flag'
    },
    {
      title: 'Participant Guide',
      description: 'Everything you need to know about the event',
      url: 'https://runsignup.com/Race/SLSP/Page/Guide',
      icon: 'bi-file-text'
    },
    {
      title: 'Goosifer Forum',
      description: 'Share your Goosifer sightings and theories',
      url: 'https://goosifer.net',
      icon: 'bi-chat-dots'
    }
  ]
};

const SLSP: React.FC<SLSPProps> = ({ onClose }) => {
  return <ProjectTemplate data={slspData} />;
};

export default SLSP;