import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typed from 'typed.js';
import { images } from '../assets';

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const el = useRef<HTMLSpanElement>(null);
  const typed = useRef<Typed | null>(null);

  useEffect(() => {
    if (el.current) {
      typed.current = new Typed(el.current, {
        strings: ['adventure seeker', 'experience curator', 'wandering explorer', 'curious creator', 'community builder'],
        typeSpeed: 100,
        backSpeed: 25,
        backDelay: 2000,
        loop: true
      });
    }

    return () => {
      if (typed.current) {
        typed.current.destroy();
      }
    };
  }, []);

  const handleNavigation = (path: string, e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(path);
  };

  return (
    <section 
      id="hero" 
      style={{ 
        backgroundImage: `url(${images.heroBackground})`
      }}
    >
      <div className="hero-overlay"></div>
      <div className="hero-container">
        <h1>Josh Manning</h1>
        <p><span ref={el}></span></p>
        <div className="hero-buttons">
          <button 
            onClick={(e) => handleNavigation('/about', e)} 
            className="hero-btn about-btn"
          >
            <i className="bi bi-person"></i>
            <span>About Me</span>
          </button>
          <button 
            onClick={(e) => handleNavigation('/projects', e)} 
            className="hero-btn projects-btn"
          >
            <i className="bi bi-lightbulb"></i>
            <span>Projects & Ideas</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;