import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface GatherlyProps {
  onClose: () => void;
}

export const gatherlyData: ProjectData = {
  id: 'gatherly',
  title: 'Gatherly',
  tagline: 'Simple event planning without accounts',
  year: '2024',
  role: 'Frustrated Event Organizer',
  status: 'ideas',
  type: 'software',
  thumbnailImage: '/img/projects/gatherly/thumbnail.png',
  overview: {
    description: "Gatherly makes it easy to join events without an account and super simple to organize events without ever needing to remember yet another username and password. You can make an event, share it with friends, and add it to your calendar in just a few clicks. It's simple to use and respects your privacy.",
    motivation: "Born from the frustration of existing event platforms that require accounts, logins, and complex setup processes. Organizing events should be as simple as sharing a link, and attending should be as easy as clicking 'RSVP'.",
    solution: "A streamlined platform that removes all barriers between wanting to gather and making it happen. No accounts required, just create an event, share the link, and let people RSVP with a single click."
  },
  keyFeatures: [
    {
      title: 'No Account Required',
      description: 'Create and join events without ever creating an account or remembering another password.',
      icon: 'bi-person-x'
    },
    {
      title: 'One-Click RSVP',
      description: 'Simple yes/no/maybe responses with optional comments. No friction, no fuss.',
      icon: 'bi-check-circle'
    },
    {
      title: 'Calendar Integration',
      description: 'Add events to your calendar with one click, supporting all major calendar platforms.',
      icon: 'bi-calendar-plus'
    },
    {
      title: 'Privacy First',
      description: 'No tracking, no ads, no data collection. Just simple event planning that respects privacy.',
      icon: 'bi-shield-check'
    }
  ],
  gallery: [
    {
      image: '/img/projects/gatherly/gallery1.jpg',
      caption: 'Event Creation Interface'
    },
    {
      image: '/img/projects/gatherly/gallery2.jpg',
      caption: 'RSVP Flow'
    },
    {
      image: '/img/projects/gatherly/gallery3.jpg',
      caption: 'Calendar Integration'
    }
  ],
  stats: [
    {
      value: '3',
      label: 'Clicks to Create',
      icon: 'bi-mouse'
    },
    {
      value: '1',
      label: 'Click to Join',
      icon: 'bi-cursor'
    },
    {
      value: '0',
      label: 'Accounts Required',
      icon: 'bi-person-x'
    }
  ],
  technologies: [
  ],
  links: [
    {
      url: 'https://gatherly.org',
      label: 'Visit Website',
      icon: 'bi-globe'
    }
  ]
};

const Gatherly: React.FC<GatherlyProps> = ({ onClose }) => {
  return <ProjectTemplate data={gatherlyData} />;
};

export default Gatherly; 