import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface SpeedfriendingProps {
  onClose: () => void;
}

export const speedfriendingData: ProjectData = {
  id: 'speedfriending',
  title: 'Speedfriending',
  tagline: 'Make friends the fast and easy way',
  year: '2025',
  role: 'Matchmaker',
  status: 'works',
  type: 'community',
  thumbnailImage: '/img/projects/speedfriending/thumbnail.png',
  overview: {
    description: "Just as it sounds - the quick and easy way to make friends! Speedfriending is a fresh way to meet like-minded people (in the Denver metro area at this time) who share your interests. It's a game-changer for meeting new people - less awkward than traditional networking and more meaningful than fleeting conversations at other types of meetup events. It's like speed dating, but for making friends!",
    motivation: "As adults, making friends can be tough - whether you're new to town, looking to expand your social circle, or just want to meet people who share your passion for hiking, craft beer, or board games. I used to organize nerdy speed dating events as part of Nerd Nite but that quickly evolved into nerdy speed friending. After moving to Denver, CO I thought this would be a great way to meet new people and help contribute to the community - especially new folks moving to the area.",
    solution: "Events that are organized just like speed dating but with a focus on making friends. Events can be organized around specific topics (i.e., outdoors, gaming, art, etc.) so that people can meet new people with similar interests."
  },
  activities: [
    {
      title: 'Speedfriending Events',
      description: '2-3 hour events with 20-40 people',
      icon: 'bi-calendar-plus'
    },
    {
      title: 'Topics of Interest',
      description: 'Track different topics and get notified when new events are created',
      icon: 'bi-tags'
    },
    {
      title: 'Lots of new friends',
      description: 'Making friends the fast and easy way',
      icon: 'bi-person-plus'
    }
  ],
  gallery: [
    {
      image: '/img/projects/speedfriending/gallery1.jpg',
      caption: 'Speedfriending'
    },
    {
      image: '/img/projects/speedfriending/gallery2.jpg',
      caption: 'Speedfriending'
    },
    {
      image: '/img/projects/speedfriending/gallery3.jpg',
      caption: 'Speedfriending'
    }
  ],
  stats: [
    {
      value: '20-40',
      label: 'People per event',
      icon: 'bi-person'
    },
    {
      value: 'Various',
      label: 'Topics of Interest',
      icon: 'bi-tags'
    },
    {
      value: '100%',
      label: 'Real Friendships!',
      icon: 'bi-person-check'
    }
  ],
  technologies: [
  ],
  links: [
    {
      url: 'https://speedfriending.org',
      label: 'Visit Website',
      icon: 'bi-globe'
    }
  ]
};

const Speedfriending: React.FC<SpeedfriendingProps> = ({ onClose }) => {
  return <ProjectTemplate data={speedfriendingData} />;
};

export default Speedfriending; 