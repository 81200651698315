import React, { useState } from 'react';
import ContactAdventure from './ContactAdventure';
import { useLocation } from 'react-router-dom';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const [showAdventure, setShowAdventure] = useState(false);
  const location = useLocation();
  const isHeroPage = location.pathname === '/home';

  const footerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '15px 0',
    background: isHeroPage 
      ? 'rgba(5, 13, 24, 0.5)' 
      : 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(8px)',
    zIndex: 100,
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)'
  };

  return (
    <>
      <footer id="footer" style={footerStyle}>
        <div className="footer-content">
          <div className="copyright">
            <span>Made with ☕️ in Colorado • </span>
            <button 
              onClick={() => setShowAdventure(true)} 
              className="contact-link"
            >
              <i className="bi bi-compass"></i>
              <span>Get in touch!</span>
            </button>
          </div>
        </div>
      </footer>
      {showAdventure && (
        <ContactAdventure onClose={() => setShowAdventure(false)} />
      )}
      <style>{`
        @media (max-width: 768px) {
          #footer > div {
            padding-left: 60px;
          }
        }
      `}</style>
    </>
  );
};

export default Footer; 